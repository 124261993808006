.maintenance-body {
    background: linear-gradient(-45deg, #da6f4b, #2f80ed, #1e8db4, #20bb95);
    background-size: 400% 400%;
    animation: maintenance-gradient 15s ease infinite;
    height: 100vh;
}

@keyframes maintenance-gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
